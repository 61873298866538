.wrapper {
  gap: 12px;
  height: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  /* min-width: 100%; */
  max-width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
  margin-left: -0.75rem;
  flex-direction: column;
  margin-right: -0.75rem;
  justify-content: flex-start;
  /* background-color: #fff; */
}

@media (max-width: 767px) {
  .wrapper {
    margin: 0;
    display: block;
    max-width: 100vw;
    background-color: #fff;
  }
}

@media (min-width: 767px) {
  .wrapper {
    align-items: flex-start;
  }
}
