.inputMessage {
  width: 85%;
  border: none;
  background: transparent;
  padding: 0;
  font-size: 15px;
  &::placeholder {
    color: #9797bd;
  }
  &:focus {
    outline: none;
  }
}
.inputSearch {
  display: flex;
  justify-content: space-between;
  //margin-right: 17px;
  background: #9797bd1a;
  border-radius: 45px;
  padding: 8px 12px 8px 50px;
  height: 45px;
  width: 100%;
  max-width: 350px;
}

// chats
.dialogues {
  display: flex;
  flex-direction: column;
}
.dialogue {
  gap: 6px;
  display: flex;
  padding: 12px 16px;
  border-bottom: 1px solid #f2f2f7;
  .info .username {
    font-size: 15px;
    text-align: left;
  }
}
.info {
  gap: 3px;
  height: 100%;
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
}
.message {
  display: flex;
  font-size: 13px;
  color: #76768c;
}
.statusWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .status {
  }
  img {
    width: 16px;
    height: 16px;
  }
  .statusUnread {
    color: #fff;
    font-size: 11px;
    border-radius: 10px;
    display: inline-block;
    padding: 2px 6px 4px 6px;
    background-color: #1dc7ff;
  }
}
// chats end

@media (max-width: 768px) {
  .mobileBorder {
    border: none !important;
    padding-right: 12px !important;
  }
  .mobileContact {
    grid-row: unset !important;
    display: flex;
  }
  .mobileInputSearch {
    margin-right: 5px;
  }
  .mobileButtonBack {
    width: 35px;
    height: 35px;
    margin-left: 9px;
    position: absolute;
    top: 0;
    margin-top: 45px;
    display: inline !important;
  }
}

@media (min-width: 768px) {
  .dialogue {
    gap: 6px;
    padding: 12px;
    .avatarWrap {
      width: 52px;
      height: 52px;
      * {
        width: 100%;
        height: 100%;
      }
    }
    .info {
      gap: 4px;
      .username {
        font-size: 16px;
        font-weight: 500;
      }
      .message {
        font-size: 14px;
        color: #1f1f2c;
      }
    }
  }
}
