.formTitle {
  font-size: 24px;
  font-weight: 500;
  font-family: "Golos Text";
}

.formCodeInput {
  display: flex !important;
  gap: 5px;
  input {
    border: none;
    font-size: 28px;
    line-height: 120%;
    text-align: center;
    border-bottom: 2px solid #d8d8dc;
    &.filled {
      border-image: linear-gradient(90deg, #6cf2fe, #2936ff, #fe2db7) 2;
    }
    &:focus {
      outline: none;
      // border-image: linear-gradient(90deg, #6cf2fe, #2936ff, #fe2db7) 2;
    }
    &::selection {
      color: #1f1f2c;
      background-color: transparent;
    }
  }
}
.formWrap {
  background-color: #fff;
}

.updateAvatarCrop {
  width: 50%;
  height: 50%;
}

.formTitle {
  margin-top: 42px;
}
.formLogo {
  width: 136px;
  height: 33px;
  display: block;
}
.formBtnReg {
  font-size: 15px;
  color: #1877f2;
  font-weight: 600;
  margin-top: 24px;
}
.formSubmitWrap {
  width: 100%;
  margin-top: 24px;
}
.formBtnForgotWrap {
  width: 100%;
  display: flex;
  margin-top: 14px;
  justify-content: flex-end;
  button {
    color: #76768c;
    font-size: 13px;
    font-weight: 500;
  }
}
.textLine {
  margin-top: 40px;
}
.blockButtons {
  gap: 10px;
  margin-top: 12px;
  justify-content: center;
}
.formTextSecondary {
  text-align: center;
  color: #76768c;
}
.formBtnBack {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  background: transparent;
  border: 1px solid #d8d8dc;
  &::before {
    content: "";
    width: 40%;
    height: 40%;
    display: block;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("../assets/img/arrowBack.svg");
  }
}
.formConfirmCodeWrap {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  position: fixed;
  overflow-y: auto;
  align-items: flex-start;
  justify-content: center;
  background-color: #fff;
}
.formConfirmCode {
  width: 100%;
  padding: 20px;
  display: flex;
  max-width: 374px;
  align-items: center;
  flex-direction: column;
  .formTitle {
    margin-top: 24px;
  }
  .formTextResend {
    button {
      border: none;
      color: #1f1f2c;
      background-color: transparent;
      border-bottom: 1px solid #1f1f2c;
    }
  }
  .formText {
    font-size: 13px;
    color: #76768c;
    margin-top: 24px;
    text-align: center;
  }
}

.inputLabel {
  width: 100%;
  font-size: 13px;
  color: #76768c;
  margin-top: 24px;
  text-align: left;
  line-height: 100%;
  margin-bottom: 4px;
  &.center {
    margin-top: 0;
    font-size: 15px;
    text-align: center;
  }
}

.passInputWrap {
  width: 100%;
  position: relative;
  .btnShow {
    border: 0;
    top: 10px;
    right: 10px;
    position: absolute;
    background: transparent;
  }
}

.inputAvatarWrap {
  overflow: hidden;
  margin-top: 26px;
  margin-bottom: 8px;
  position: relative;
  object-fit: contain;
  width: 73px !important;
  height: 73px !important;
  border-radius: 100% !important;
  background: linear-gradient(
    196.07deg,
    #fe2db7 11.13%,
    #2936ff 57.29%,
    #6cf2fe 87.79%
  );
  * {
    cursor: pointer;
  }
  .circle {
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    background: #fff;
    border-radius: 100%;
    background-size: 25px 25px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(../assets/img/camera.fill.svg);
  }
  &.load {
    animation: rotation 1s infinite linear;
    .circle {
      top: 2px;
      left: 2px;
      right: 2px;
      bottom: 2px;
      background-image: none;
    }
  }

  @keyframes rotation {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(359deg);
    }
  }

  input {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
    z-index: 999;
    position: absolute;
  }
  img {
    z-index: 99;
    width: 100%;
    height: 100%;
    padding: 1px;
    position: relative;
    border-radius: 100%;
  }
}

@media (max-width: 767px) {
  .authLayout {
    background-color: #fff;
  }
  .btns-mobile {
    flex-direction: column;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .customShadow {
    margin: 0 auto;
    width: 300px;
    height: 10px;
    position: relative;
    margin-bottom: 10px;
    -webkit-box-shadow: 0 10px 10px #00000008;
    -moz-box-shadow: 0 10px 10px #00000008;
    box-shadow: 0 10px 10px #00000008;
  }
  .formConfirmCodeWrap {
    background-color: #f2f2f7;
  }
  .formConfirmCode {
    width: 1000px;
    padding: 38px;
    max-width: 100%;
    margin: 100px 30px 50px 30px;
    background-color: #fff;
  }
}

@media (max-height: 980px) {
  .formConfirmCode {
    margin-top: 50px;
  }
}

// .div {
//   background-color: #fff;
// }
