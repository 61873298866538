.privacyPoliceWrap {
  display: flex;
  height: 100dvh;
  overflow: hidden;
  flex-direction: column;
}

.privacyPolice {
  flex: 1;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 16px;
  font-size: 13px;
  * {
    font-size: 13px !important;
  }
}

@media (min-width: 768px) {
  .privacyPoliceWrap {
    width: 1000px;
    height: 80dvh;
    max-width: 100vw;
    margin: 50px auto;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 10px 0px;
  }
}

@media (max-height: 900px) {
  .privacyPoliceWrap {
    margin-top: 50px;
  }
}

@media (max-width: 768px) {
  .privacyPoliceWrap {
    margin-top: 0;
  }
}
