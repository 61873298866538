.aside {
  background-color: rgb(250, 250, 250);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin-top: 65px;
  border-radius: 10px;
  max-height: 390px;
  position: sticky;
  top: 133px;
  margin-left: 55px;
}
