@import "~bootstrap/scss/bootstrap";
@import "recomendation";
@import "mobileStyles";

@mixin textLineMixin($bottom) {
  content: "";
  width: 40%;
  bottom: $bottom;
  position: absolute;
  border-bottom: 1px solid #ebebeb;
}

.modalAlert {
  left: 0;
  width: 100vw;
  bottom: 80px;
  display: flex;
  z-index: 999;
  position: fixed;
  animation: 1;
  justify-content: center;
  p {
    width: 200px;
    padding: 12px;
    text-align: center;
    border-radius: 10px;
    background-color: #dadada;
    animation: 0.3s 1 alternate slidein;
    box-shadow: 0px -1px 8px 0px #00000024;
  }
}

.loaderWrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 20px;
    height: 20px;
    animation: 1s infinite linear rotation;
  }
}

@keyframes rotation {
  from {
    rotate: 0;
  }
  to {
    rotate: 359deg;
  }
}

// style={{ boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 10px 0px" }}
.customBoxShadow {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 10px 0px;
}

// .moreWrap {
//   right: 5px;
//   padding: 9px 16px;
//   position: absolute;
//   border-radius: 10px;
//   top: calc(100% + 10px);
//   background-color: #fff;
//   box-shadow: 0px 3px 4px 0px #00000014;
//   box-shadow: 0px -1px 8px 0px #00000024;
// }

.onlyMob {
  display: none;
}

.onlyDesk {
  display: block;
}

.modal {
  height: 100dvh !important;
}

.uploadIcon {
  width: 100%;
  height: 100%;
  padding: 2px;
  cursor: pointer;
  background: linear-gradient(
    196.07deg,
    #fe2db7 11.13%,
    #2936ff 57.29%,
    #6cf2fe 87.79%
  );
  border-radius: 100px;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 100px;
    background-color: #fff;
    background-size: 12px 12px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url('data:image/svg+xml,<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_247_5561)"><path d="M7.5 1C7.5 0.447715 7.05228 0 6.5 0C5.94772 0 5.5 0.447715 5.5 1V11C5.5 11.5523 5.94772 12 6.5 12C7.05228 12 7.5 11.5523 7.5 11V1Z" fill="%231F1F2C"/><path d="M1.5 5C0.947715 5 0.5 5.44772 0.5 6C0.5 6.55228 0.947715 7 1.5 7H11.5C12.0523 7 12.5 6.55228 12.5 6C12.5 5.44772 12.0523 5 11.5 5H1.5Z" fill="%231F1F2C"/></g><defs><clipPath id="clip0_247_5561"><rect width="12" height="12" fill="white" transform="translate(0.5)"/></clipPath></defs></svg>');
  }
  img {
    display: none;
  }
}

.cropper-wrap {
  z-index: 9999;
}
.cropper {
  border-radius: 0 0 20px 20px;
  .cropper-dashed {
    opacity: 0.5;
    border: 0 solid #ebebeb;
  }
  .cropper-container,
  .cropper-drag-box,
  .cropper-wrap-box {
    border-radius: 0 0 20px 20px;
  }
  .cropper-wrap-box {
    overflow: hidden;
  }
  .cropper-line {
    background-color: #aeaeb2;
  }
  .cropper-point {
    padding: 3px;
    border: 2px solid #aeaeb2;
    background-color: transparent;
    &.point-nw,
    &.point-ne,
    &.point-n {
      top: -5px;
    }
    &.point-se,
    &.point-e,
    &.point-ne {
      right: -5px;
    }
    &.point-nw,
    &.point-w,
    &.point-sw {
      left: -5px;
    }
    &.point-se,
    &.point-s,
    &.point-sw {
      bottom: -5px;
    }
  }
  .cropper-view-box {
    outline-color: #aeaeb2;
  }
}

.infoFooterWrap {
  gap: 12px;
  align-items: center;
  flex-direction: column;
  margin-top: 12px !important;
  margin-bottom: 40px !important;
  span {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  a,
  a:visited {
    font-size: 14px;
    color: #1f1f2c;
    line-height: 110%;
    border-bottom: 1px solid #1f1f2c;
  }
}

.formErrMesWrap {
  width: 100%;
  position: relative;
  &.center {
    span {
      width: 100%;
      margin-top: 5px;
      text-align: center;
    }
  }
}

.textLine {
  gap: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  span {
    color: #76768c;
    white-space: nowrap;
  }
  &:before,
  &:after {
    content: "";
    width: 100%;
    display: block;
    border-bottom: 1px solid #ebebeb;
  }
}

.textLineSignUP {
  display: flex;
  &:after {
    @include textLineMixin(9%);
    right: 0;
  }
  &:before {
    @include textLineMixin(9%);
    left: 0;
  }
}

.line {
  border: 1px solid #ebebeb;
  width: 72px;
}

.inputSearch {
  position: relative;
  background: #efefef;
  border-radius: 8px;
  padding: 0 10px 0 32px;
  min-width: 250px;
}

.inputSearch__input {
  width: 100%;
  border: none;
  background: transparent;
  padding: 0;
  &::placeholder {
    color: #a7a7a7;
  }
  &:focus {
    outline: none;
  }
}

.inputSearch__icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.inputSearch__placeholder {
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  color: #a7a7a7;
  pointer-events: none;
}

.inputSearch__input:focus + .inputSearch__placeholder {
  display: none;
}

.active_profile::before {
  content: "";
  display: block;
  width: 3px;
  height: 100%;
  background-color: #000;
  position: absolute;
  border-radius: 2px;
  left: -0.8rem;
  top: 0;
}

.change_btn {
  &:active {
    transform: scale(0.95);
    transition: transform 0.2s ease;
  }
}

// header
.headerWrap {
  padding: 22px 40px;
  .dark,
  .light {
    width: 25px;
    height: 25px;
    display: flex;
    object-fit: contain;
    a,
    svg,
    img,
    button {
      width: 100%;
      height: 100%;
    }
  }
  .dark {
    display: none;
  }
  .is-active {
    .dark {
      display: flex;
    }
    .light {
      display: none;
    }
  }
  .main-span {
    width: 28px;
    height: 28px;
  }
  .d-flex {
    width: auto;
    align-items: center;
    justify-content: space-between;
  }
}
// header end

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1023px) {
    @content;
  }
}

@keyframes slidein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
