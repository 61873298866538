.modalHeader {
  font-weight: bold;
  font-size: large;
}

.modalContentWrap {
  position: absolute;
}

.CommentDelete {
  position: absolute;
  right: 0px;
}

.Delete,
.Report {
  right: 5px;
  width: calc(100%);
  padding: 9px 16px;
  border-radius: 10px;
  top: calc(100% + 10px);
  background-color: #fff;
  box-shadow: 0px 3px 4px 0px #00000014;
  box-shadow: 0px -1px 8px 0px #00000024;
}

.hr {
  border: none; /* Убираем границу */
  background-color: black; /* Цвет линии */
  color: black; /* Цвет линии для IE6-7 */
  height: 1px; /* Толщина линии */
  width: 100%;
}
.Delete {
  cursor: pointer;
  font-weight: bold;
  color: #ff3b30;
}

.Report {
  cursor: pointer;
  font-weight: bold;
  color: #ff3b30;
}
